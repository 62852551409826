<template>
    <BT-Blade-Items
        addBladeName="customer-order-schedule"
        bladeName="customer-order-schedules"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        :headers="[
            { text: 'Name', value: 'scheduleName', title: 1 },
            { text: 'Next', value: 'nextAttentionDate', textFilter: 'toShortDateAndTime', subtitle: 1 }]"
        navigation="customer-order-schedules"
        title="Process Schedules"
        useServerPagination>
        <template slot-scope="{ item }">
            <v-list-item-content>
                <v-list-item-title>{{ item.groupName }}</v-list-item-title>
                <v-list-item-subtitle>Next: {{ item.nextAttentionDate | toShortDateAndTime }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Customer-Order-Schedules-Blade',
    props: {
        bladesData: null
    }
}
</script>